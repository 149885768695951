import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import ShareButtons from '../components/share/shareButtons';
import Seo from '../components/seo';
import BotNav from '../components/bottomNav';
import Footer from '../components/homeNav/footer/Footer';
import PageNav from '../components/homeNav/PageNav';

function BlogPostTemplate({ data, location }) {
  const post = data ? data.markdownRemark : null;
  const { previous, next } = data;
  const twitterHandle = 'TripleaimSoftw1';
  const related = ['TripleaimSoftw1', 'Okichibrahim'];

  return (
    <>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        keywords={post.frontmatter.keywords}
        image={post.frontmatter.image}
        siteUrl={location.href}
        canonical={location.href}
      />
      <PageNav />
      <Container>
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h1 itemProp="headline" css="color: #06546B">{post.frontmatter.title}</h1>
            <p css="font-size: clamp(0.5rem,1vw, 0.75rem);">
              Posted By Dr. Ibrahim Okich on
              {post.frontmatter.date}
            </p>
          </header>
          <section
            css="list-style-position: inside;font-size: clamp(1rem,1vw, 1.25rem);"
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
          <script type="application/ld+json">
            {`{
                        "@context": "https://schema.org/",
                        "@type": "BlogPosting",
                        "headline": "${post.frontmatter.title}",
                        "datePublished": "${post.frontmatter.date}",
                        "dateModified": "${post.frontmatter.date}",
                        "description": "${post.frontmatter.description}",
                        "articleBody": "${post}",
                        "image": "${post.frontmatter.image}",
                        "author": {
                          "@type": "Person",
                          "name": "Dr Ibrahim Okich",
                          "url":"https://www.linkedin.com/in/ibrahim-okich/"
                        },
                        "publisher": {
                          "@type": "Organization",
                          "name": "Afyabook",
                          "url":"https://www.afyabook.com/"
                        }
                      }`}
          </script>
          <hr />
          <div>
            Share it on
            <ShareButtons
              title={post.frontmatter.title}
              url={location.href}
              tags={post.frontmatter.tags}
              twitterHandle={twitterHandle}
              related={related}
              description={post.frontmatter.description}
            />
          </div>

        </article>
        <br />
        <nav className="blog-post-nav">
          <ul
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              listStyle: 'none',
              textAlign: 'center',
              padding: 0,
            }}
          >
            <li css="background-color: #E5E5E5; width: auto; height: auto; border-radius: 10px; margin-bottom: 3px; font-size: 20px">
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ←
                  {' '}
                  {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li css="background-color: #E5E5E5; width: auto; height: auto; border-radius: 10px;font-size: 20px">
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title}
                  {' '}
                  →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </Container>
      <BotNav />
      <Footer />
    </>
  );
}

export default BlogPostTemplate;

const Container = styled.div`
  margin-top: 7rem;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  //font-family: oswald;
  

  @media screen and (min-width: 600px) {
    max-width: 50%;

  @media screen and (min-width: 1000px) {
    max-width: 35%;
}
`;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        keywords
        image 
     }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
