import React from 'react';
import styled from 'styled-components';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
} from 'react-share';

function ShareButtons({
  title, url, twitterHandle, tags, related, description,
}) {
  return (
    <Container>
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon size={40} round />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title} via={twitterHandle} hashtags={tags} related={related}>
        <TwitterIcon size={40} round />
      </TwitterShareButton>

      <LinkedinShareButton url={url} title={title} source={url} summary={description}>
        <LinkedinIcon size={40} round />
      </LinkedinShareButton>

      <RedditShareButton url={url} title={title}>
        <RedditIcon size={40} round />
      </RedditShareButton>

      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon size={40} round />
      </WhatsappShareButton>
    </Container>
  );
}
export default ShareButtons;

const Container = styled.div`
    
    &:hover {
        transform: scale(1.1)
    }

    @media screen and (min-width: 600px) {
        display: flex;
    justify-content: center;
    }
`;
